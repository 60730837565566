import React, { useEffect, useContext, useCallback, useState, useRef } from 'react';
import styled from "styled-components";
import logo from "../assets/logo.png";
import { AuthContext } from '../components/Auth';
import Main from "../components/Main";
import MButton from "../components/MButton";
import { FiLogIn, FiX } from "react-icons/fi";
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FcGoogle } from "react-icons/fc";

const HeaderBox = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  background-color: #333333;
  box-shadow: inset 0px 0px 20px 0px rgba(255, 255, 255, 0.2);
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) { 
    justify-content: space-between;
    flex-direction: row;
  }
`;

const ResponsiveMButton = styled(MButton)`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    width: 15%;
  }
`;

const SmallScreenMButton = styled(MButton)`
  @media (min-width: 768px) {
    display: none;
  }
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  width: 250px;
`;

const B1Text = styled.h2`
  font-weight: 300;
`;
const T1 = styled.h1`
  font-weight: 400;
  color: #a6a6a6;
  text-align: center;
  font-family: "Lexend Exa";
`;
const T2 = styled.h2`
  font-weight: 200;
  font-size: 20px;
  color: #a6a6a6;
  text-align: center;
  font-family: "Lexend Exa";

`;
const LoginSVG = styled(FiLogIn)`
  font-size: 15px;
`
const GoogleSVG = styled(FcGoogle)`
  font-size: 15px;
`

const CloseButton = styled(FiX)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #a6a6a6;
  cursor: pointer;
  &:hover {
    color: #f6bc03;
  }
`

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupContent = styled.div`
  position: relative;
  padding: 20px;
  border-radius: 5px;
  background-color: #333333;
  box-shadow: inset 0px 0px 20px 0px rgba(255, 255, 255, 0.2);
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
`;

const LoginInput = styled.input`
  padding: 15px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 15px;
  background-color: #333333;
  color: #a6a6a6;
  font-family: "Lexend Exa";
  
  box-shadow: inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
        inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
  &:focus {
    
  }
`;

const Landing = () => {
  const { isAuthenticated, login } = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const popupRef = useRef(null);
  const loginButtonRef = useRef(null);
  //const path = 'http://localhost:8080/login';
  const path = 'https://synergy-back-end.onrender.com/login';
  const handleCredentialResponse = useCallback(async (response) => {
    const { credential } = response;
    try {
      const res = await axios.post(path, { credential });
      const { token } = res.data;
      const decodedToken = jwtDecode(token);
      const userName = decodedToken.userName;
      sessionStorage.setItem("userName", userName); 
      login(token);
    } catch (error) {
      console.error('Error logging in:', error);
    }
  }, [login]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: '35925415750-9hhfctan129mk7442pvbe57mjvakpdce.apps.googleusercontent.com',
        callback: handleCredentialResponse
      });
    };
    document.body.appendChild(script);

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) && loginButtonRef.current && !loginButtonRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleCredentialResponse]);
  useEffect(() => {
  }, [showPopup]);

  const handleClick = (event) => {
    event.stopPropagation();
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleGClick = () => {
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    if (window.google && window.google.accounts && window.google.accounts.id) {
      window.google.accounts.id.prompt();
    } else {
      console.error('Google Identity Services library not loaded');
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await fetch(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });
  const data = await response.json();
  if (data.token) {
    //localStorage.setItem('token', data.token);
    login(data.token);
  } else {
    console.log('Error Token Not Found');
  }
  };

  useEffect(() => {
    if (isAuthenticated) {
      console.log('User is authenticated');
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
      return <Main />;
    }

  return (
    <>
      <HeaderBox>
        <LogoBox>
          <img height="25px" width="142px" src={logo} alt="logo" />
        </LogoBox>
        <ResponsiveMButton ref={loginButtonRef} onClick={handleClick} >
          <LoginSVG />
          <B1Text>Continue</B1Text>
        </ResponsiveMButton>
      </HeaderBox>
      <SmallScreenMButton ref={loginButtonRef} onClick={handleClick} >
        <LoginSVG />
        <B1Text>Continue</B1Text>
      </SmallScreenMButton>

      {showPopup && (
        <PopupContainer>
          <PopupContent ref={popupRef}>
            <CloseButton onClick={handleClose} />
            <T1>Login</T1>
            <T2>Only Registered Org Mail</T2>
            <LoginForm onSubmit={handleLogin}>
              <LoginInput
                type="email"
                style={{ display: 'none' }}
                autoComplete="new-password"
              />
              <LoginInput
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="new-password"
                name="new-email"
              />
              <LoginInput
                type="password"
                style={{ display: 'none' }}
                autoComplete="new-password"
              />
              <LoginInput
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
                name="new-password"
              />
              <MButton type="submit"><B1Text>Login</B1Text></MButton>
            </LoginForm>
            <hr style={{borderColor: "#a6a6a6", margin: "20px"}} />
            <MButton style={{margin: "20px"}} onClick={handleGClick}><B1Text><GoogleSVG /> Continue With Google</B1Text></MButton>
          </PopupContent>
        </PopupContainer>
      )}
    </>
  );
};

export default Landing;