import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
            navigate('/dashboard');
        }
    }, [navigate]);

    const login = (token) => {
      sessionStorage.setItem('token', token);
      setIsAuthenticated(true);
      navigate('/dashboard');
    };
  
    const logout = () => {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('userName');
      setIsAuthenticated(false);
      navigate('/');
    };

    return (
      <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
};