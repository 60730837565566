import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../Pages/Dashboard';
import Tasks from '../Pages/Tasks';
import Header from './Header';
import SideBar from './SideBar';
import { useContext } from 'react';
import { AuthContext } from './Auth';
import { Navigate } from 'react-router-dom';
import MButton from './MButton';
import Settings from '../Pages/Settings';
import styled from 'styled-components';
import { useState } from 'react';

const Content = styled.div`
  display: flex;
  flex:1;
  justify-content: center;
`;

function Main() {
  const { isAuthenticated } = useContext(AuthContext);
  const [isopen, setIsOpen] = useState(true);

  const contentStyle = isopen ? {} : { flexGrow: 1 };


  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
 
  return (
    <div className="View">
      <Header style={{ justifyContent: 'center' }} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
      <SideBar isopen={isopen} setIsOpen={setIsOpen}>
        <MButton style={{ margin: '10px auto' }}>Login</MButton>
      </SideBar>
      <Content style={contentStyle} >
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/tasks" element={<Tasks />} /> 
          <Route path="/settings" element={<Settings />} />
        </Routes>
      </Content>
      </div>
    </div>
  );
}

export default Main;