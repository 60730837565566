import './App.css';
import Landing from './Pages/Landing';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from './components/Main';
import { AuthProvider, AuthContext } from './components/Auth';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="*" element={<ProtectedRoute />} />
      </Routes>
    </AuthProvider>
  );
}

const ProtectedRoute = () => {
  const { isAuthenticated } = React.useContext(AuthContext);
  if (isAuthenticated) {
    return (
      <Routes>
        <Route path="*" element={<Main />} />
        <Route path="/main" element={<Main />} />
      </Routes>
    );
  } else {
    return <Landing />;
  }
};

export default App;