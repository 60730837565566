import React from "react";
import styled from "styled-components";
import {
  FiHome,
  FiCheckSquare,
  FiPenTool,
  FiBarChart2,
  FiSettings,
  FiChevronLeft,
  FiLogOut
} from "react-icons/fi";
import { Link } from "react-router-dom";
import MButton from "./MButton";
import { useContext } from "react";
import { AuthContext } from "./Auth";

const SideBarBox = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  width: 250px;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  background-color: #333333;
  flex-direction: column;
  box-shadow: inset 0px 0px 20px 0px rgba(255, 255, 255, 0.2);
  transform: translateX(${(props) => (props['data-isopen'] ? "0" : "-100%")});
  transition: transform 0.2s ease;
`;
const BottomBox = styled.div`
  display: flex;
  gap: 5%;
  justify-content: center;
  padding: 0 0 10px 0;
`;

const SideBarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
  flex-direction: column;
`;

const Toggle = styled.button`
  position: fixed;
  top: 50%;
  transform: ${(props) => (props.$isopen === 'true' ? "translateX(230px)" : "translateX(-20px)")};
  z-index: ${(props) => (props.$isopen === 'true' ? "1" : "0")};
  transition: transform 0.1s ease;
  border: 0;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #333333;
  box-shadow: inset 0px 0px 12px 0px rgba(255, 255, 255, 0.2);

  &:hover {
    box-shadow: inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
      inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
    color: #f6bc03;
    transition: 0.1s;
    cursor: pointer;
  }
`;
const B1Text = styled.h2`
  font-weight: 300;
`;
const B2Text = styled.h2`
  font-weight: 200;
`;

const ScrollerBox = styled.div`
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
  max-height: calc(100vh - 235px);
  direction: rtl;
  &::-webkit-scrollbar {
    width: 2px;
    
  }
  &::-webkit-scrollbar-track {
    background: transparent;

  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
  }
`;

const HomeSVG = styled(FiHome)`
  font-size: 15px;
`;
const TaskSVG = styled(FiCheckSquare)`
  font-size: 15px;
`;
const PenSVG = styled(FiPenTool)`
  font-size: 15px;
`;
const BarSVG = styled(FiBarChart2)`
  font-size: 15px;
`;
const SettingsSVG = styled(FiSettings)`
  font-size: 20px;
  padding: 0 5px 0 5px;
`;
const LogoutSVG = styled(FiLogOut)`
  font-size: 20px;
  padding: 0 5px 0 5px;
`;
const LeftSVG = styled(FiChevronLeft)`
  font-size: 25px;
  color: #a6a6a6;
  transition: 0.1s;
  ${Toggle}:hover & {
    font-size: 30px;
    padding-right: 10px;
  }
`;
const Linker = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const SideBar = ({ isopen, setIsOpen }) => {
  const sideBarStyle = isopen ? { width: '250px', transition: 'width 0.5s' } : { width: '0', transition: 'width 0.5s' };
  const handleToggle = () => {
    setIsOpen(!isopen);
  };

  const { logout } = useContext(AuthContext);

  return (
    <>
    <Toggle $isopen={isopen.toString()} onClick={handleToggle}>
          <LeftSVG style={{ transform: `rotate(${isopen ? "0deg" : "180deg"})`, paddingRight: isopen ? "5px" : "10px" }}/>
    </Toggle>
    <div style={sideBarStyle}>
    <SideBarBox data-isopen={isopen} >
      <SideBarItem>
        <div>
          <MButton style={{ margin: "15px 0 10px 0", width: "100%" }}>
            <HomeSVG />
            <Linker to="/dashboard">
            <B1Text>Dashboard</B1Text>
            </Linker>
          </MButton>
          <ScrollerBox>
            <MButton>
              <TaskSVG />
              <Linker to="/tasks">
              <B2Text>Tasks</B2Text>
              </Linker>
            </MButton>
            <MButton>
              <PenSVG />
              <B2Text>Projects</B2Text>
            </MButton>
            <MButton>
              <PenSVG />
              <B2Text>Results</B2Text>
            </MButton>
            <MButton>
              <PenSVG />
              <B2Text>Attendance</B2Text>
            </MButton>
            <MButton>
              <PenSVG />
              <B2Text>Circulars</B2Text>
            </MButton>
            <MButton>
              <PenSVG />
              <B2Text>Feed</B2Text>
            </MButton>
            <MButton>
              <PenSVG />
              <B2Text>Classroom</B2Text>
            </MButton>
            <MButton>
              <PenSVG />
              <B2Text>Goal</B2Text>
            </MButton>
            <MButton>
              <PenSVG />
              <B2Text>Resume</B2Text>
            </MButton>
            <MButton>
              <PenSVG />
              <B2Text>Certificates</B2Text>
            </MButton>
            <MButton>
              <PenSVG />
              <B2Text>SWOT</B2Text>
            </MButton>
            <MButton>
              <PenSVG />
              <B2Text>DT</B2Text>
            </MButton>
            <MButton>
              <BarSVG />
              <B2Text>Salary</B2Text>
            </MButton>
          </ScrollerBox>
        </div>
        <BottomBox>
        <Linker to="/settings">
        <MButton>
          <SettingsSVG />
          {/* <B1Text>Settings</B1Text> */}
        </MButton>
        </Linker>
        <MButton onClick={logout}>
        <LogoutSVG />
        {/* <B1Text>LogOut</B1Text> */}
      </MButton>
      </BottomBox>
      </SideBarItem>
    </SideBarBox>
    </div>
    </>
  );
};

export default SideBar;
