import moon from '../assets/moon.svg'

const Tasks = () => {
  return (
    <div>
      <img src={moon} alt="Under Construction"/>
    </div>
  );
};
export default Tasks;
