import styled from "styled-components";

const MButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Lexend Exa";
    border-radius: 15px;
    width: 90%;
    gap: 5px;
    border: 0px;
    height: 50px;
    padding: 20px;
    margin: 10px auto;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    box-shadow: inset 7px 7px 20px 0px rgba(255, 255, 255, 0.2),
      4px 4px 5px 0px rgba(0, 0, 0, 0.5);
    outline: none;
    background: #333333;
    border: none;
    color: #a6a6a6;
    direction: ltr;
    &:before {
      height: 0%;
      width: 2px;
    }
    &:hover {
      box-shadow: inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
        inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
      color: #f6bc03;
    }
    @media (min-width: 768px) {
      margin: 10px;
    }
  `;

export default MButton;
