import moon from '../assets/moon.svg'

const Settings = () => {
  return (
    <div>
      <img src={moon} alt="Under Construction"/>
    </div>
  );
};
export default Settings;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import styled from 'styled-components';
// import { FiEdit3  } from "react-icons/fi"; 
// import moon from '../assets/moon.svg'

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
// `;

// const ProfileImage = styled.img`
//   width: 100px;
//   height: 100px;
//   border-radius: 50%;
//   object-fit: cover;
// `;

// const Input = styled.input`
//   margin: 10px 0;
//   padding: 10px;
//   border-radius: 5px;
//   border: 1px solid #ccc;
// `;
// const EditIcon = styled(FiEdit3 )`
//   width: 20px;
//   height: 20px;
//   cursor: pointer;
// `;

// const Button = styled.button`
//   margin-top: 20px;
//   padding: 10px 20px;
//   border-radius: 5px;
//   border: none;
//   background-color: #007bff;
//   color: white;
//   cursor: pointer;
// `;
// const Settings = () => {
//   const [profile, setProfile] = useState({
//     picture: '',
//     name: '',
//     password: '',
//     email: '',
//     number: '',
//     secondaryEmail: ''
//   });

//   const [isEditable, setIsEditable] = useState({
//     name: false,
//     password: false,
//     number: false,
//     secondaryEmail: false
//   });

//   useEffect(() => {
//     const token = sessionStorage.getItem('token');
//     console.log(token); 
//     axios.get('http://localhost:8080/profile', {
//         headers: {
//             'Authorization': `Bearer ${token}`
//         }
//     })
//     .then(response => {
//         setProfile(response.data);
//     })
//     .catch(error => {
//         console.error('Error fetching data:', error);
//     });
// }, []);

// const updateProfile = (field, value) => {
//   const token = sessionStorage.getItem('token');
//   axios.put(`http://localhost:8080/profile/:${field}`, { value }, {
//       headers: {
//           'Authorization': `Bearer ${token}`
//       }
//   })
//   .then(response => {
//       setProfile(prevProfile => ({ ...prevProfile, [field]: value }));
//   })
//   .catch(error => {
//       console.error('Error updating data: ', error);
//   });
// };

// const saveChanges = () => {
//   const token = sessionStorage.getItem('token');
//   console.log(token);
//   axios.put('http://localhost:8080/profile', profile, {
//       headers: {
//           'Authorization': `Bearer ${token}`
//       }
//   })
//   .then(response => {
//       alert('Changes updated successfully');
//   })
//   .catch(error => {
//       console.error('Error saving changes: ', error);
//   });
// };
//   return (
//     <Container>
//       <ProfileImage src={profile.picture || moon} alt="Profile" />
//       <Input type="file" onChange={(e) => updateProfile('picture', e.target.files[0])} />
//       {['name', 'password', 'number', 'secondaryEmail'].map(field => (
//   isEditable[field] ? (
//     <Input key={field} type={field === 'password' ? 'password' : 'text'} value={profile[field]} onChange={(e) => updateProfile(field, e.target.value)} placeholder={`Enter your ${field}`} />
//   ) : (
//     <div key={field}>
//       <p>{profile[field] || `Enter your ${field}`}</p>
//       <EditIcon onClick={() => setIsEditable(prevState => ({ ...prevState, [field]: true }))} />
//     </div>
//   )
// ))}
//       <Button onClick={saveChanges}>Save Changes</Button>
//     </Container>
//   );
// };

// export default Settings;