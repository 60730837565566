import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../assets/logo.png";
import sunriseSvg from "../assets/sunrise.svg";
import sunSvg from "../assets/sun.svg";
import sunsetSvg from "../assets/sunset.svg";
import moonSvg from "../assets/moon.svg";

const HeaderBox = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  background-color: #333333;
  align-items: center;
  box-shadow: inset 0px 0px 20px 0px rgba(255, 255, 255, 0.2);
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  width: 250px;
`;

const GreetingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  padding: 0px 20px;
  color: #a6a6a6;
  font-weight: 300;
  font-family: "Lexend Exa";
  @media (max-width: 767px) {
    display: none;
  }
`;

const GreetingSvg = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 10px;
`;

const Header = () => {
  const [userName, setUserName] = useState("");
  const [greeting, setGreeting] = useState("");
  const [svg, setSvg] = useState("");

  useEffect(() => {
    const fetchUserName = () => {
      let userName = sessionStorage.getItem("userName");
      if (userName) {
        // Split the username at the first space
        userName = userName.split(' ')[0];
        // Capitalize the first letter and make the rest lowercase
        userName = userName.charAt(0).toUpperCase() + userName.slice(1).toLowerCase();
      }      setUserName(userName);
    };

    fetchUserName();
  }, []);

  useEffect(() => {
    // Determine the time of day
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    // Set greeting and SVG based on the time of day
    if (currentHour >= 5 && currentHour < 12) {
      setGreeting("Good Morning");
      setSvg(sunriseSvg);
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting("Good Afternoon");
      setSvg(sunSvg);
    } else if (currentHour >= 18 && currentHour < 22) {
      setGreeting("Good Evening");
      setSvg(sunsetSvg);
    } else {
      setGreeting("Good Night");
      setSvg(moonSvg);
    }
  }, []);

  return (
    <HeaderBox>
      <LogoBox>
        <img height="25px" width="142px" src={logo} alt="logo" />
      </LogoBox>
      <GreetingBox>
        <GreetingSvg src={svg} alt="greeting-svg" />
        {greeting} {userName}
      </GreetingBox>
    </HeaderBox>
  );
};

export default Header;
